.wizard-container {
   display: flex;
  overflow-y: hidden;
  height: 100vh;


.wizard-sidebar {
  overflow-y: hidden;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex: 0.1 0 auto;
  -ms-flex: 0.1 0 auto;
  flex: 0.1 0 auto;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.wizard-step {
  padding: 10px;
  cursor: pointer;
  color: rgb(99, 115, 129);
  font-weight: 500;
}

.wizard-step:hover {
  background-color: rgba(95, 95, 95, 0.04);
}

.wizard-step.active {
  background-color: rgba(25, 118, 210, 0.08);
  color: rgb(32, 101, 209);
}

.wizard-content {
 flex: 1;
overflow-y: auto; 
padding: 10px; 
}

.step-content {
  display: none;
}

.step-content.active {
  display: block;
}
}