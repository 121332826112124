body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  padding: 8px;
  width: 200px;
  overflow: inherit;
  .firstBox {
    margin: 12px 0px;
    padding: 0px 20px;
  }
  .stack {
    padding: 8px;
  }
  .divider {
    border-style: dashed;
  }
  .menuItem {
    margin: 8px;
  }
}
