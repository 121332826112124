.json-input {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  width: 100%;

  label {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    position: absolute;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    left: 0;
    top: 0;
    -webkit-transform: translate(14px, 16px) scale(1);
    -moz-transform: translate(14px, 16px) scale(1);
    -ms-transform: translate(14px, 16px) scale(1);
    transform: translate(14px, 16px) scale(1);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    -webkit-transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: none;
  }
  .baseInput {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    cursor: text;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #c9c6c6;
    padding: 16.5px 14px;
    pre {
      color: #0a0909;
    }
    pre,
    textarea {
      padding: 0 !important;
      background-color: transparent !important;
      min-height: 10em;
      display: block;
      line-height: 1.5;
      margin: 0 !important;
      white-space: pre-wrap;
      word-break: break-all;
      grid-area: 1 / 1 / 2 / 2;
      font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
      font-size: 0.875em !important;
    }

    code {
      color: inherit;
    }
    textarea {
      color: transparent !important ;
      border: none;
      height: auto;
      color: transparent;
      caret-color: #000;
      overflow: hidden;
      resize: none;
      &:hover,
      &:focus {
        outline: 0;
      }
      &::placeholder {
        color: transparent;
      }
    }
  }
  &.focused .baseInput {
    border-color: #1976d2;
    border-width: 2px;
  }
  &.error .baseInput {
    border-color: #d32f2f !important;
    border-width: 1px !important;
  }
  &.error label {
    color: #d32f2f !important;
  }
  &.focused label {
    background-color: white;
    max-width: calc(100% - 24px);
    color: #1976d2;
    transform: translate(14px, -10px) scale(0.75);
    -webkit-transform: translate(14px, -10px) scale(0.75);
    -moz-transform: translate(14px, -10px) scale(0.75);
    -ms-transform: translate(14px, -10px) scale(0.75);
    pointer-events: auto;
    user-select: none;
    padding: 4px;
  }
  .errorMessage {
    color: #d32f2f;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
  }
  .hide {
    display: none;
  }
}
