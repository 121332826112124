.logoWrapper {
  max-width: 100%;
  height: auto;
  @media (max-width: $xs) {
    img {
      width: 50px;
    }
  }
  @media (min-width: $sm) {
    img {
      width: 70px;
    }
  }
  @media (min-width: $md) {
    img {
      width: 100px;
    }
  }
}
