.sidbarWrapper {
  .logoBox {
    padding: 16px;
    display: inline-flex;
  }
  @media (min-width: $lg) {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: $DRAWER_WIDTH;
  }

  .scrollWrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
    overflow-x: auto;
    max-height: 100vh;
    scrollbar-color: rgb(63, 61, 65) rgb(239, 243, 239);
    scrollbar-width: thin;
    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 50vh;
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .accountBox {
    margin: 0 20px 20px 40px;
    .accountStyle {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 16px 20px;
      border-radius: 6px;
      background-color: green;
      .nameStyle {
        margin-left: 16px;
      }
    }
  }
  .listWrapper {
    padding: 8px;
  }
  .emptyRow {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
    width: $DRAWER_WIDTH;
    background-color: #fff;
    border-right-style: dashed;
  }
}

.SidbarNavItemWrapper {
  color: #637381;
  .listItemStyle {
    line-height: 22 / 14;
    font-size: 14/16;
    height: 48px;
    position: relative;
    text-transform: capitalize;
    border-radius: 4px;
  }
  .ListItemIconStyle {
    width: 22px;
    height: 22px;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .activeRootStyle {
    color: $primary-main;
    font-weight: $fontWeightMedium;
    background-color: rgba(25, 118, 210, 0.08);
    border-radius: 8px;
  }
  .activeSubStyle {
    font-weight: $fontWeightMedium;
    color: $primary-main;
  }
  .iconBox {
    background-color: #3d3939 !important;
    width: 0.4rem;
    height: 0.4rem;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    transition: transform;
    &.isActiveSub {
      transform: scale(2);
      background-color: #007bff !important;
    }
  }
}
.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  width: $DRAWER_WIDTH !important;
}
