.validator-provider-container {
  .validator-header {
    background-color: white;
    z-index: 2;
    position: fixed;
    top: $APPBAR_DESKTOP;
    height: $APPBAR_MOBILE;
    width: 100%;
    padding-left: 0.5rem;
    border-bottom: 1px solid #e0e0e0;
    @media (min-width: $lg) {
      top: $APPBAR_DESKTOP;
      height: $APPBAR_DESKTOP;
    }
  }
  .filter-table-content {
    margin-top: $APPBAR_MOBILE;
    @media (min-width: $lg) {
      margin-top: $APPBAR_DESKTOP;
    }
  }
  .filter-icon {
    display: flex;
    padding: 0.5rem;
  }
  .create {
    color: rgb(74, 171, 87);
  }
  .done {
    color: rgb(7, 87, 179);
  }
  .error {
    color: rgb(124, 42, 7);
  }
  .processing {
    color: rgb(181, 169, 11);
  }
}
