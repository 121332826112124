.create-service-wrapper {
  margin: 1rem 1rem 0 1rem;
  .container {
    display: flex;
    flex-direction: column;
    height: 90vh;
  }
  .button-container {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    justify-content: flex-end;
  }
  .label-button {
    background-color: white;
    color: #1565c0;
    margin-bottom: 4px;
    margin-top: 2px;

    &:hover {
      background-color: rgba(25, 118, 210, 0.04);
    }
  }
  .label-input {
    margin-bottom: 0.5rem;
  }
  .label-error {
    height: 16px;
    display: block;
  }

  .divider {
    margin-top: 0.5rem;
  }
  .wizard-error{ 
    color: #d32f2f;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}
}
