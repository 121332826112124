.table-container {
  .body-table-cell {
    .check-icon {
      color: #1976d2;
    }
  }
  .table-header {
    background-color: #c7c4c424;
    .table-header-cell {
      font-weight: bold;
      font-size: 1rem;
    }
    .tabel-checkbox {
      .MuiSvgIcon-root path {
        stroke-width: 12px;
        color: black;
      }
    }
  }
}
