.dashboard {
  display: flex;
  min-height: 100%;
  overflow: hidden;
  .dashboardNavbar {
    box-shadow: none;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    background-color: rgb(85, 104, 245);
    @media (min-width: $lg) {
      width: calc(100% - ($DRAWER_WIDTH + 1px));
      // width: calc(100% - 100px);
    }

    .toolbarStyle {
      min-height: $APPBAR_MOBILE;
      @media (min-width: $lg) {
        min-height: $APPBAR_DESKTOP;
        padding: 0px 40px;
      }
      .dashboardIcon {
        margin-right: 8px;
        color: inherit;
        @media (min-width: $lg) {
          display: none;
        }
      }
      .flex-grow-1 {
        flex-grow: 1;
      }
    }
  }
  .mainWrapper {
    flex-grow: 1;
    overflow: auto;
    min-height: 100%;
    padding-top: $APPBAR_DESKTOP + 4;
    padding-bottom: $APPBAR_DESKTOP + 4;
    @media (min-width: $lg) {
      padding-top: $APPBAR_DESKTOP + 10;
    }
  }
}
