.dropdown-project {
  .dropdown-project-content {
    padding: 0.25rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
    width: 200px;
    height: 40px;
    background-color: white;
    color: black;
    margin: 0.25rem;
    cursor: pointer;
    &:hover {
      background-color: rgb(216 222 231);
    }

    .box {
      flex: 1;
      padding: 3px;
    }
    .select-project {
      width: 100px;
      height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
