.projects-container {
  .project-header {
    background-color: white;
    z-index: 2;
    position: fixed;
    top: $APPBAR_DESKTOP;
    height: $APPBAR_MOBILE;
    width: 100%;
    padding-left: 0.5rem;
    border-bottom: 1px solid #e0e0e0;
    @media (min-width: $lg) {
      top: $APPBAR_DESKTOP;
      height: $APPBAR_DESKTOP;
    }
  }
  .filter-table-content {
    margin-top: $APPBAR_MOBILE;
    @media (min-width: $lg) {
      margin-top: $APPBAR_DESKTOP;
    }
  }
  .filter-icon {
    display: flex;
    padding: 0.5rem;
  }
  .add-project-container-for-empty-data {
    margin-top: $APPBAR_MOBILE;
    @media (min-width: $lg) {
      margin-top: $APPBAR_DESKTOP;
    }
  }
  .bulk-action-header {
    background-color: #d1e9fc;
    padding: 0.5rem 0 0.5rem 0.5rem;
    border-bottom: 1px solid #e0e0e0;
    .border-radius {
      .css-1d6wzja-MuiButton-startIcon {
        margin: 0;
      }
      border-radius: 50%;
      min-width: 0;
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: #b4d5ed;
      }
    }
    .close-icon {
      color: #3498db;
    }
  }
}
