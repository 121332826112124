.create-project-wrapper {
  margin: 2rem;
  .label-button {
    background-color: white;
    color: #1565c0;
    &:hover {
      background-color: rgba(25, 118, 210, 0.04);
    }
  }
  .label-input {
    margin-bottom: 0.5rem;
  }
  .label-error {
    height: 16px;
    display: block;
  }

  .divider {
    margin-top: 0.5rem;
  }
}
