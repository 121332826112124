.publicHeader {
  .login {
    @media (max-width: $xs) {
      font-size: 14px;
    }
  
  }

  .menuBoxXs {
    flex-grow: 1;
    @media (max-width: $xs) {
      display: flex;
    }
    @media (min-width: $sm) {
      display: none;
    }
  }
  .menuBoxMd {
    flex-grow: 1;
    @media (max-width: $xs) {
      display: none;
    }
    @media (min-width: $sm) {
      display: flex;
    }
  }

  a {
    text-decoration: none;
    color: #f8efef;
    margin: 1rem;
    padding: 0.5rem 1rem 0.4rem 1rem;
    @media screen and (min-width: 320px) {
      font-size: 0.5rem;
    }
    @media screen and (min-width: 768px) {
      font-size: 1rem;
    }
    font-weight: 700;

    &:hover {
      text-decoration: none;
      color: white;
      border: none;
      border-radius: 6px;
      background-image: linear-gradient(to right, #acbcc5, #81c0eb);
    }

    a[class="active"] {
      background-image: linear-gradient(to right, #920d1f, #81c0eb);
    }
  }
}

