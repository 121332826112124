.condition-wrapper {
  .accordion {
    border: 1px solid #c2c2c2a3;
    border-bottom: 0;
    &:before {
      display: none;
    }
  }

  .accordion-summary {
    &:hover {
      background-color: rgba(25, 118, 210, 0.08);
    }
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      position: relative;
    }

    .delete-icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &:hover .delete-icon {
      visibility: visible;
      opacity: 1;
    }
  }
  .accordion-detail{
    padding-top: 16px;
    overflow-y: auto;
    max-height: 50vh;
  }
  .add-button-in-accordion {
    border-radius: 4px !important;
    border: 1px solid #c2c2c2a3 !important;
  }
}
